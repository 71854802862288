import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { openErrorNotification } from 'common/helpers';

export const aiParametersGetByDeviceId = createAsyncThunk(
  'device/ai-parameters/get',
  async (deviceId, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      if (deviceId) {
        const response = await api.get(`/device/${deviceId}/detections`);
        return response.data;
      }

      return [];
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const aiParameterCreate = createAsyncThunk(
  'device/ai-parameter/create',
  async (
    { deviceId, name, score },
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const response = await api.post(`/device/${deviceId}/detections`, {
        name,
        score,
      });
      dispatch(aiParametersGetByDeviceId(deviceId));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const aiParameterDelete = createAsyncThunk(
  'device/ai-parameter/delete',
  async (
    { deviceId, parameterId },
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const response = await api.delete(
        `/device/${deviceId}/detections/${parameterId}`,
      );
      dispatch(aiParametersGetByDeviceId(deviceId));
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const aiParameterUpdate = createAsyncThunk(
  'device/ai-parameters/update',
  async (
    { deviceId, parameterId, score },
    { extra: { createAuthenticatedAPI }, rejectWithValue, dispatch },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const response = await api.patch(
        `/device/${deviceId}/detections/${parameterId}`,
        {
          score,
        },
      );

      dispatch(aiParametersGetByDeviceId(deviceId));
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  availableParameters: [],
  deviceParameters: [],
  pending: false,
  error: null,
};

export const aiParameterSlice = createSlice({
  name: 'aiParameters',
  initialState,
  reducers: {
    resetDeviceParameters: (state) => {
      state.deviceParameters = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(aiParameterCreate.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(aiParameterCreate.pending, (state) => {
        state.pending = true;
      })
      .addCase(aiParameterCreate.rejected, (state) => {
        openErrorNotification('Failed to create an AI Parameter.');
        state.pending = false;
      })
      .addCase(aiParameterDelete.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(aiParameterDelete.pending, (state) => {
        state.pending = true;
      })
      .addCase(aiParameterDelete.rejected, (state) => {
        openErrorNotification('Failed to delete an AI Parameter.');
        state.pending = false;
      })
      .addCase(aiParameterUpdate.fulfilled, (state) => {
        state.pending = false;
      })
      .addCase(aiParameterUpdate.pending, (state) => {
        state.pending = true;
      })
      .addCase(aiParameterUpdate.rejected, (state) => {
        openErrorNotification('Failed to update AI Parameter.');
        state.pending = false;
      })
      .addCase(aiParametersGetByDeviceId.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.deviceParameters = payload;
      })
      .addCase(aiParametersGetByDeviceId.pending, (state) => {
        state.pending = true;
      })
      .addCase(aiParametersGetByDeviceId.rejected, (state) => {
        openErrorNotification('Failed to get AI Parameters.');
        state.pending = false;
      });
  },
});

export const { resetDeviceParameters } = aiParameterSlice.actions;

export const selectPending = (state) => state.aiParameters.pending;
export const selectDeviceParameters = (state) =>
  state.aiParameters.deviceParameters;

export default aiParameterSlice.reducer;
