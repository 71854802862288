import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleAPIError } from 'common/helpers';
import { notification } from 'antd';

export const retrieveDevice = createAsyncThunk(
  'device/retrieve',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.get(`/device/list/${payload.id}`);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteDevice = createAsyncThunk(
  'device/delete',
  async (
    { subscriptionId, deviceId },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.delete(
        `/subscription/subscriptions/${subscriptionId}/devices/${deviceId}`,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceChangePassword = createAsyncThunk(
  'device/changePassword',
  async (
    { deviceId, newPassword },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return api.post(`/device/${deviceId}/passwords`, { newPassword });
    } catch (err) {
      handleAPIError(err, 'Change password failed');
      return rejectWithValue(err);
    }
  },
);

export const deviceUpdate = createAsyncThunk(
  'device/update',
  async (
    { deviceId, name, description, deviceGroupId, newDeviceGroupName },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.patch(`/device/${deviceId}`, {
        name,
        description,
        deviceGroupId,
        newDeviceGroupName,
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deviceUpdateAIDetection = createAsyncThunk(
  'device/updateAIDetection',
  async (
    {
      deviceId,
      enableAIObjectDetection,
      enablePedestrianDetection,
      enableFireDetection,
      enablePlateRecognition,
      enableRoadConeDetection,
    },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.post(`/device/${deviceId}/settings`, {
        enablePedestrianDetection: enablePedestrianDetection ? 1 : 0,
        enableAIObjectDetection: enableAIObjectDetection ? 1 : 0,
        enableFireDetection: enableFireDetection ? 1 : 0,
        enablePlateRecognition: enablePlateRecognition ? 1 : 0,
        enableRoadConeDetection: enableRoadConeDetection ? 1 : 0,
      });
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const retrieveDevicePhoto = createAsyncThunk(
  'device/retrieve/photo',
  async (payload, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.get(`/image/images/${payload.photoId}`);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const retrieveDevicePhotos = createAsyncThunk(
  'device/retrieve/photos',
  async (
    {
      id,
      page = 1,
      from,
      to,
      limit = 30,
      search = '',
      fileName = '',
      subscriptionId,
    },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      const { data, total } = await api.post('/image/search', {
        subscriptionId,
        fileName,
        page,
        from,
        to,
        limit,
        search,
        deviceId: id,
      });
      return { data, total };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteDevicePhoto = createAsyncThunk(
  'device/delete/photo',
  async ({ id }, { extra: { createAuthenticatedAPI }, rejectWithValue }) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.delete(`/image/images/${id}`);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const retrieveDevicePhotoNotification = createAsyncThunk(
  'device/retrieve/notification',
  async (
    { deviceId, photoId },
    { extra: { createAuthenticatedAPI }, rejectWithValue },
  ) => {
    const api = createAuthenticatedAPI();
    try {
      return await api.get(
        `/device/devices/${deviceId}/notifications?photoId=${photoId}`,
      );
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  },
);

const initialState = {
  device: null,
  notifications: [],
  photo: {
    previewUrl: '',
    url: '',
    updatedAt: '',
    detections: [],
  },
  total: 0,
  photos: [],
  rectangle: {},
  pendingGet: false,
  pendingDelete: false,
  pendingUpdate: false,
  pendingPhoto: false,
  pendingPhotos: false,
  pendingDeletePhoto: false,
  pendingUpdateAIDetection: false,
  pendingNotification: false,
  error: null,
};

export const deviceSlice = createSlice({
  name: 'deviceDetail',
  initialState,
  reducers: {
    setSelectedPhoto: (state, action) => {
      state.selectedPhoto = action.payload;
    },
    setRectangle: (state, action) => {
      state.rectangle = action.payload;
    },
    reset: (state) => {
      state.photo = {};
      state.notifications = [];
      state.photos = [];
      state.device = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveDevice.fulfilled, (state, { payload }) => {
        state.pendingGet = false;
        state.device = payload;
      })
      .addCase(retrieveDevice.pending, (state) => {
        state.pendingGet = true;
      })
      .addCase(retrieveDevice.rejected, (state) => {
        state.pendingGet = false;
      })
      .addCase(deleteDevice.fulfilled, (state) => {
        state.pendingDelete = false;
      })
      .addCase(deleteDevice.pending, (state) => {
        state.pendingDelete = true;
      })
      .addCase(deleteDevice.rejected, (state) => {
        state.pendingDelete = false;
      })
      .addCase(retrieveDevicePhoto.fulfilled, (state, { payload }) => {
        state.pendingPhoto = false;
        state.photo = payload;
      })
      .addCase(retrieveDevicePhoto.pending, (state) => {
        state.pendingPhoto = true;
        state.photo = {
          previewUrl: '',
          url: '',
          updatedAt: '',
        };
        state.notifications = [];
      })
      .addCase(retrieveDevicePhoto.rejected, (state) => {
        state.pendingPhoto = false;
      })
      .addCase(retrieveDevicePhotos.fulfilled, (state, { payload }) => {
        state.pendingPhotos = false;
        state.photos = payload.data;
        state.total = payload.total;
      })
      .addCase(retrieveDevicePhotos.pending, (state) => {
        state.pendingPhotos = true;
        state.photos = [];
        state.photo = {
          previewUrl: '',
          url: '',
          updatedAt: '',
        };
        state.notifications = [];
      })
      .addCase(retrieveDevicePhotos.rejected, (state) => {
        state.pendingPhotos = false;
      })
      .addCase(deleteDevicePhoto.fulfilled, (state) => {
        state.pendingDeletePhoto = false;
        state.photo = {
          previewUrl: '',
          url: '',
          updatedAt: '',
        };
        state.notifications = [];
      })
      .addCase(deleteDevicePhoto.pending, (state) => {
        state.pendingDeletePhoto = true;
      })
      .addCase(deleteDevicePhoto.rejected, (state) => {
        state.pendingDeletePhoto = false;
      })
      .addCase(deviceUpdate.fulfilled, (state) => {
        state.pendingUpdate = false;
      })
      .addCase(deviceUpdate.pending, (state) => {
        state.pendingUpdate = true;
      })
      .addCase(deviceUpdate.rejected, (state) => {
        state.pendingUpdate = false;
      })
      .addCase(deviceChangePassword.fulfilled, () => {
        notification.success({
          message: 'Change Password',
          description:
            'New password sent to email. Please update your device settings to continue uploading image.',
          duration: 2,
        });
      })
      .addCase(deviceChangePassword.pending, () => {})
      .addCase(deviceChangePassword.rejected, (state, { error }) => {
        if (error) {
          handleAPIError(error.message, 'Change Password');
        }
      })
      .addCase(deviceUpdateAIDetection.fulfilled, (state) => {
        state.pendingUpdateAIDetection = false;
      })
      .addCase(deviceUpdateAIDetection.pending, (state) => {
        state.pendingUpdateAIDetection = true;
      })
      .addCase(deviceUpdateAIDetection.rejected, (state) => {
        state.pendingUpdateAIDetection = false;
      })
      .addCase(
        retrieveDevicePhotoNotification.fulfilled,
        (state, { payload }) => {
          state.pendingNotification = false;
          state.notifications = payload.data;
        },
      )
      .addCase(retrieveDevicePhotoNotification.pending, (state) => {
        state.pendingNotification = true;
        state.notifications = [];
      })
      .addCase(retrieveDevicePhotoNotification.rejected, (state) => {
        state.pendingNotification = false;
      });
  },
});

export const { reset, setRectangle } = deviceSlice.actions;

export const selectDevice = (state) => state.deviceDetail.device;
export const selectPhoto = (state) => state.deviceDetail.photo;
export const selectPhotos = (state) => state.deviceDetail.photos;
export const selectTotalPhotos = (state) => state.deviceDetail.total;
export const selectRectangle = (state) => state.deviceDetail.rectangle;
export const selectNotifications = (state) => state.deviceDetail.notifications;
export const selectPending = (state) => state.deviceDetail.pendingGet;
export const selectPendingPhoto = (state) => state.deviceDetail.pendingPhoto;
export const selectPendingPhotos = (state) => state.deviceDetail.pendingPhotos;
export const selectPendingDeletePhoto = (state) =>
  state.deviceDetail.pendingDeletePhoto;
export const selectPendingUpdate = (state) => state.deviceDetail.pendingUpdate;
export const selectPendingUpdateAIDetection = (state) =>
  state.deviceDetail.pendingUpdateAIDetection;
export const selectPendingNotification = (state) =>
  state.deviceDetail.pendingNotification;

export default deviceSlice.reducer;
