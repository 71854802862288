import React from 'react';
import { Input, Button, Form } from 'antd';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import useDeviceSharing from '../hooks';

function Share(props) {
  const { deviceId } = props;
  const [form] = Form.useForm();
  // const [currentInvite, setCurrentInvite] = useState('');

  const {
    inviteDeviceUsers,
    pendingDeviceInvite,
    // pendingGetDeviceUserPendingInvites,
    // pendingDeleteDeviceUserPendingInvite,
    // deviceUserPendingInvites,
    // deleteDeviceUserPendingInvites,
    getDeviceUserPendingInvites,
  } = useDeviceSharing();

  const handleSendInvites = (data) => {
    const { email } = data;
    inviteDeviceUsers(deviceId, email).then(() => {
      form.setFieldsValue({ emails: '' });
    });
  };

  // const handleCancelInvite = (inviteId) => {
  //   setCurrentInvite(inviteId);
  //   deleteDeviceUserPendingInvites(deviceId, inviteId);
  // };

  useEffectOnce(() => {
    if (deviceId) {
      getDeviceUserPendingInvites(deviceId);
    }
  });

  useUpdateEffect(() => {
    if (deviceId) {
      getDeviceUserPendingInvites(deviceId);
    }
  }, [deviceId]);

  return (
    <div className="relative h-full">
      <Form form={form} onFinish={handleSendInvites}>
        <Form.Item name="email">
          <Input placeholder="Enter email address you want to invite, separated by comma" />
        </Form.Item>
        {/* <div className="scrollbar h-[calc(50vh-190px)] overflow-y-scroll"> */}
        {/*  <Spin spinning={pendingGetDeviceUserPendingInvites}> */}
        {/*    <List */}
        {/*      dataSource={deviceUserPendingInvites} */}
        {/*      renderItem={(item) => ( */}
        {/*        <List.Item> */}
        {/*          <Skeleton avatar title={false} loading={false} active> */}
        {/*            <List.Item.Meta title={item.email} /> */}
        {/*            <Space size={20}> */}
        {/*              <Button */}
        {/*                loading={ */}
        {/*                  pendingDeleteDeviceUserPendingInvite && */}
        {/*                  currentInvite === item.id */}
        {/*                } */}
        {/*                type="default" */}
        {/*                onClick={() => handleCancelInvite(item.id)} */}
        {/*              > */}
        {/*                Cancel Invite */}
        {/*              </Button> */}
        {/*            </Space> */}
        {/*          </Skeleton> */}
        {/*        </List.Item> */}
        {/*      )} */}
        {/*    /> */}
        {/*  </Spin> */}
        {/* </div> */}
        <div className=" flex w-full justify-end">
          <Button
            loading={pendingDeviceInvite}
            htmlType="submit"
            type="primary"
          >
            Send Invite
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Share;
